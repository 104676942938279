//  win95 theme from cybrespace.

//  Modified by kibi! to use webpack package syntax for urls (eg,
//  `url(~images/…)`) for easy importing into skins.

$win95-bg: #bfbfbf;
$win95-dark-grey: #404040;
$win95-mid-grey: #808080;
$win95-window-header: #00007f;
$win95-tooltip-yellow: #ffffcc;
$win95-blue: blue;
$win95-cyan: #008080;

$ui-base-lighter-color: $win95-dark-grey;
$ui-highlight-color: $win95-window-header;

@mixin win95-border-outset() {
  border-left: 2px solid #efefef;
  border-top: 2px solid #efefef;
  border-right: 2px solid #404040;
  border-bottom: 2px solid #404040;
  border-radius:0px;
}

@mixin win95-border-outset-sides-only() {
  border-left: 2px solid #efefef;
  border-right: 2px solid #404040;
  border-radius:0px;
}

@mixin win95-outset() {
  box-shadow: inset -1px -1px 0px #000000,
              inset 1px 1px 0px #ffffff,
              inset -2px -2px 0px #808080,
              inset 2px 2px 0px #dfdfdf;
  border-radius:0px;
}

@mixin win95-outset-no-highlight() {
  box-shadow: inset -1px -1px 0px #000000,
              inset -2px -2px 0px #808080;
  border-radius:0px;
}

@mixin win95-border-inset() {
  border-left: 2px solid #404040;
  border-top: 2px solid #404040;
  border-right: 2px solid #efefef;
  border-bottom: 2px solid #efefef;
  border-radius:0px;
}

@mixin win95-border-slight-inset() {
  border-left: 1px solid #404040;
  border-top: 1px solid #404040;
  border-right: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  border-radius:0px;
}

@mixin win95-inset() {
  box-shadow: inset 1px 1px 0px #000000,
              inset -1px -1px 0px #ffffff,
              inset 2px 2px 0px #808080,
              inset -2px -2px 0px #dfdfdf;
  border-width:0px;
  border-radius:0px;
}


@mixin win95-tab() {
  box-shadow: inset -1px 0px 0px #000000,
              inset 1px 0px 0px #ffffff,
              inset 0px 1px 0px #ffffff,
              inset 0px 2px 0px #dfdfdf,
              inset -2px 0px 0px #808080,
              inset 2px 0px 0px #dfdfdf;
  border-radius:0px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}

@mixin win95-border-groove() {
  border-radius: 0px;
  border: 2px groove #bfbfbf;
}

@mixin win95-reset() {
  box-shadow: unset;
  border: 0px solid transparent;
}

@font-face {
  font-family:"premillenium";
  src: url('~fonts/premillenium/MSSansSerif.ttf') format('truetype');
}

@import 'application';

/* borrowed from cybrespace style: wider columns and full column width images */

@media screen and (min-width: 1300px) {
  .drawer {
    width: 17%; /* Not part of the flex fun */
    max-width: 400px;
    min-width: 330px;
  }
  .layout-multiple-columns .column {
    flex-grow: 1 !important;
    max-width: 400px;
  }
}

/* Don't show outline around statuses if we're in
 * mouse or touch mode (rather than keyboard) */
[data-whatinput="mouse"], [data-whatinput="touch"] {
  .status__content:focus, .status:focus,
  .status__wrapper:focus, .status__content__text:focus {
    outline: none;
  }
}

/* Less emphatic show more */
.status__content__read-more-button {
  font-size: 14px;
  color: $dark-text-color;

  .status__prepend-icon {
    padding-right: 4px;
  }
}

/* Show a little arrowey thing after the time in a
 * status to signal that you can click it to see
 * a detailed view */
.status time:after,
.detailed-status__datetime span:after {
  font: normal normal normal 14px/1 FontAwesome;
  content: "\00a0\00a0\f08e";
}

/* Don't display the elephant mascot (we have our
 * own, thanks) */
.drawer__inner__mastodon {
  display: none;
}

/* Let the compose area/drawer be short, but
 * expand if necessary */
.drawer .drawer__inner {
  overflow: visible;
  height:inherit;
  background-image: none;
}
.drawer__pager {
  overflow-y:auto;
}

/* Put a reasonable background on the single-column compose form */
.layout-single-column .compose-panel {
  background-color: $ui-base-color;
  height: auto;
  max-height: 100%;
  overflow-y: visible;
  margin-top: 65px;
}

/* Better distinguish the search bar */
.layout-single-column .compose-panel .search {
  position:relative;
  top: -55px;
  margin-bottom: -55px;
}

/* Use display: none instead of visibility:hidden
 * to hide the suggested follows list on non-mobile */
@media screen and (min-width: 630px) {
  .search-results .trends {
     display:none;
  }
}

/* Don't display the weird triangles on the modal layout,
 * because they look strange on cybrespace themes. */
.modal-layout__mastodon {
  display:none;
}

/* main win95 style */

html {
  scrollbar-color: $win95-mid-grey transparent;
}

body {
  font-size:13px;
  font-family: "MS Sans Serif", "premillenium", sans-serif;
  color:black;
}

.ui,
.ui .columns-area,
body.admin {
  background: $win95-cyan;
}

.loading-bar {
  height:5px;
  background-color: #000080;
}

.tabs-bar__wrapper {
  background-color: $win95-cyan;
}

.tabs-bar {
  background: $win95-bg;
  @include win95-outset();
  height: 30px;
}

.tabs-bar__link {
  color:black;
  border:2px outset $win95-bg;
  border-top-width: 1px;
  border-left-width: 1px;
  margin:2px;
  padding:3px;
}

.tabs-bar__link.active {
  @include win95-inset();
  color:black;
}

.tabs-bar__link:last-child::before {
  content:"Start";
  color:black;
  font-weight:bold;
  font-size:15px;
  width:80%;
  display:block;
  position:absolute;
  right:0px;
}

.tabs-bar__link:last-child {
  position:relative;
  flex-basis:60px !important;
  font-size:0px;
  color:$win95-bg;

  background-image: url("~images/start.png");
  background-repeat:no-repeat;
  background-position:8%;
  background-clip:padding-box;
  background-size:auto 50%;
}

.drawer .drawer__inner {
  overflow: visible;
  height:inherit;
  background:$win95-bg;
}

.drawer:after {
    display:block;
    content: " ";

    position:absolute;
    bottom:15px;
    left:15px;
    width:132px;
    height:117px;
    background-image:url("~images/clippy_wave.gif"), url("~images/clippy_frame.png");
    background-repeat:no-repeat;
    background-position: 4px 20px, 0px 0px;
    z-index:0;
}

.drawer__pager {
  overflow-y:auto;
  z-index:1;
}

.privacy-dropdown__dropdown {
  z-index:2;
}

.column > .scrollable {
  background: $win95-bg;
  @include win95-border-outset();
  border-top-width:0px;
}

.column-header__wrapper {
  color:white;
  font-weight:bold;
  background:#7f7f7f;
}

.column-header {
  padding:0px;
  font-size:13px;
  background:#7f7f7f;
  @include win95-border-outset();
  border-bottom-width:0px;
  color:white;
  font-weight:bold;
  align-items:baseline;
  min-height: 24px;
}

.column-header > button {
  padding: 0px;
  min-height: 22px;
}

.column-header__wrapper.active {
  background:$win95-window-header;
}

.column-header__wrapper.active::before {
  display:none;
}
.column-header.active {
  box-shadow:unset;
  background:$win95-window-header;
}

.column-header.active .column-header__icon {
  color:white;
}

.column-header__buttons {
  max-height: 20px;
  margin: 2px;
  margin-left: -2px;
}

.column-header__buttons button {
  margin-left: 2px;
}

.column-header__button {
  background: $win95-bg;
  color: black;
  @include win95-outset();

  line-height:0px;
  font-size:14px;
  padding:0px 4px;

  &:hover {
    color: black;
  }
}

.column-header__button.active, .column-header__button.active:hover {
  @include win95-inset();
  background-color:#7f7f7f;
}

// selectivity -- needs to override .column-header > button
.column-header .column-header__back-button {
  background: $win95-bg;
  color: black;
  padding:2px;
  padding-right: 4px;
  max-height: 20px;
  min-height: unset;
  margin: 2px;
  @include win95-outset();
  font-size: 13px;
  line-height: 17px;
  font-weight:bold;
}

.column-header__buttons .column-header__back-button {
    margin: 0;
}

.column-back-button {
  background:$win95-bg;
  color:black;
  @include win95-outset();
  font-size:13px;
  font-weight:bold;

  padding: 2px;
  height: 26px;
}

.column-back-button--slim-button {
  position:absolute;
  top:-22px;
  right:4px;
  max-height:20px;
  padding: 1px 6px 0 2px;
  box-sizing: border-box;
}

.column-back-button__icon {
  font-size:11px;
  margin-top:-3px;
}

.column-header__collapsible {
  border-left:2px outset $win95-bg;
  border-right:2px outset $win95-bg;
}

.column-header__collapsible-inner {
  background:$win95-bg;
  color:black;
}

.column-header__collapsible__extra {
  color:black;
}

.column-header__collapsible__extra div[role="group"] {
  border: 2px groove #eee;
  margin-bottom: 11px;
  padding: 4px;
}

.column-inline-form {
  background-color: $win95-bg;
  @include win95-border-outset();
  border-bottom-width:0px;
  border-top-width:0px;

  align-items: baseline;
}

.column-inline-form .icon-button {
    font-size: 14px!important;
    line-height: 17px!important;
}

.column-inline-form .setting-text {
    line-height: 17px;
    padding-left: 4px;
}

.column-settings__section {
  color:black;
  font-weight:bold;
  font-size:11px;
}

[role="group"] .column-settings__section {
  display:inline-block;
  background-color:$win95-bg;
  position:relative;

  top: -14px;
  top: calc(-1em - 0.5ex);
  left: 4px;

  padding: 0px 4px;
  margin-bottom: 0px;
}

.setting-meta__label, .setting-toggle__label {
  color:black;
  font-weight:normal;
}

.setting-meta__label span:before {
  content:"(";
}
.setting-meta__label span:after {
  content:")";
}

.setting-toggle {
  line-height:13px;
}

.react-toggle .react-toggle-track {
  border-radius:0px;
  background-color:white;
  @include win95-border-inset();

  width:12px;
  height:12px;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color:white;
}

.react-toggle .react-toggle-track-check {
  left:2px;
  transition:unset;
}

.react-toggle .react-toggle-track-check svg path {
  fill: black;
}

.react-toggle .react-toggle-track-x {
  display:none;
}

.react-toggle .react-toggle-thumb {
  border-radius:0px;
  display:none;
}

.text-btn {
  background-color:$win95-bg;
  @include win95-outset();
  padding:4px;
}

.text-btn:hover {
  text-decoration:none;
  color:black;
}

.text-btn:active {
  @include win95-inset();
}

.setting-text {
  color:black;
  background-color:white;
  @include win95-inset();
  font-size:13px;
  padding:2px;
}

.setting-text:active, .setting-text:focus,
.setting-text.light:active, .setting-text.light:focus {
  color:black;
  border-bottom:2px inset $win95-bg;
}

.column-header__setting-arrows .column-header__setting-btn {
  padding:3px 10px;
}

.column-header__setting-arrows .column-header__setting-btn:last-child {
  padding:3px 10px;
}

.missing-indicator {
  background-color:$win95-bg;
  color:black;
  @include win95-outset();
}

.missing-indicator > div {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAACXBIWXMAAC4jAAAuIwF4pT92AAAAF3pUWHRUaXRsZQAACJnLyy9Jyy/NSwEAD5IDblIFOhoAAAAXelRYdEF1dGhvcgAACJlLzijKz0vMAQALmgLoDsFj8gAAAQpJREFUOMuVlD0OwjAMhd2oQl04Axfo0IGBgYELcAY6cqQuSO0ZOEAZGBg6VKg74gwsEaoESRVHjusI8aQqzY8/PbtOEz1qkFSn2YevlaNOpLMJh2DwvixhuXtOa6/LCh51DUMEFkAsgAZD207Doin8mQ562JpRE5CHBAAhmIqD1L8AqzUUUJkxc6kr3AgAJ+NuvIWRdk7WcrKl0AUqcIBBHOiEbpS4m27mIL5Onfg3k0rgggeQuS2sDOGSahKR+glgqaGLgUJs951NN1q9D72cQqQWR9cr3sm9YcEssEuz6eEuZh2bu0aSOhQ1MBezu2O/+TVSvEFII3qLsZWrSA2AAUQIh1HpyP/kC++zjVSMj6ntAAAAAElFTkSuQmCC') no-repeat;
  background-position:center center;
}

.empty-column-indicator,
.error-column {
  background: $win95-bg;
  color: black;
}

.notification__filter-bar {
  background: $win95-bg;
  @include win95-border-outset-sides-only();
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 2px solid #efefef;
  overflow-y: visible;

  button {
    background: transparent;
    color: black;
    padding: 8px 0;
    align-self: end;
    @include win95-tab();

    &.active {
      color: black;
      top: 2px;
      background-color: $win95-bg;

      &::before, &::after {
        display:none;
      }
    }
  }
}

.status__wrapper {
  border: 2px groove $win95-bg;
  margin:4px;
}

.status {
  @include win95-border-slight-inset();
  background-color:white;
  margin:4px;
  padding-bottom:40px;
  margin-bottom:8px;
}

.status.status-direct {
  background:$win95-bg;
  &:focus, &:active {
    background:$win95-bg;
  }

  &:not(.read) {
    background: white;
  }
}
.focusable:focus .status.status-direct {
  background:$win95-bg;
}

[data-whatinput="mouse"], [data-whatinput="touch"] {
  .status__content:focus, .status:focus,
  .status__wrapper:focus, .status__content__text:focus {
    background-color: $win95-bg;
  }

  .status.status-direct, .detailed-status {
    &:not(.read) {
      .status__content:focus {
        background-color: white;
      }
    }
  }
}

.status__content, .reply-indicator__content {
  font-size:13px;
  color: black;
}

.status.light .status__relative-time,
.status.light .display-name span {
  color: #7f7f7f;
}

.status__action-bar {
  box-sizing:border-box;
  position:absolute;
  bottom:-1px;
  left:-1px;
  background:$win95-bg;
  width:calc(100% + 2px);
  padding-left:10px;
  padding: 4px 2px;
  padding-bottom:4px;
  border-bottom:2px groove $win95-bg;
  border-top:1px outset $win95-bg;
  text-align: right;
}

.status__wrapper .status__action-bar {
  border-bottom-width:0px;
}

.status__action-bar-button {
  float:right;
}

.status__action-bar-dropdown {
  margin-left:auto;
  margin-right:10px;

  .icon-button {
    min-width:28px;
  }
}
.status.light .status__content a {
  color:blue;
}

.focusable:focus {
  background: $win95-bg;
  .detailed-status__action-bar {
    background: $win95-bg;
  }

  .status, .detailed-status {
    background: white;
    outline:2px dotted $win95-mid-grey;
  }
}

.dropdown__trigger.icon-button {
  padding-right:6px;
}

.detailed-status__action-bar-dropdown .icon-button {
  min-width:28px;
}

.detailed-status {
  background:white;
  background-clip:padding-box;
  margin:4px;
  border: 2px groove $win95-bg;
  padding:4px;
}

.detailed-status__display-name {
  color:#7f7f7f;
}

.detailed-status__display-name strong {
  color:black;
  font-weight:bold;
}
.account__avatar,
.account__avatar-overlay-base,
.account__header__avatar,
.account__avatar-overlay-overlay {
  @include win95-border-slight-inset();
  clip-path:none;
  filter: saturate(1.8) brightness(1.1);
}

.detailed-status__action-bar {
  background-color:$win95-bg;
  border:0px;
  border-bottom:2px groove $win95-bg;
  margin-bottom:8px;
  justify-items:left;
  padding-left:4px;
}

.icon-button {
  background:$win95-bg;
  @include win95-border-outset();
  padding:0px 0px 0px 0px;
  margin-right:4px;

  color:#3f3f3f;
  &.inverted, &:hover, &.inverted:hover, &:active, &:focus {
    color:#3f3f3f;
  }
}

.icon-button:active {
  @include win95-border-inset();
}

.status__action-bar > .icon-button {
  padding:0px 15px 0px 0px;
  min-width:25px;
}

.icon-button.star-icon,
.icon-button.star-icon:active {
  background:transparent;
  border:none;
}

.icon-button.star-icon.active {
  color: $gold-star;
  &:active,  &:hover, &:focus {
    color: $gold-star;
  }
}

.icon-button.star-icon > i {
  background:$win95-bg;
  @include win95-border-outset();
  padding-bottom:3px;
}

.icon-button.star-icon:active > i {
  @include win95-border-inset();
}

.text-icon-button {
  color:$win95-dark-grey;
}

.detailed-status__action-bar-dropdown {
  margin-left:auto;
  justify-content:right;
  padding-right:16px;
}

.detailed-status__button {
  flex:0 0 auto;
}

.detailed-status__button .icon-button {
  padding-left:2px;
  padding-right:25px;
}

.status-card, .status-card.compact, a.status-card, a.status-card.compact {
  border-radius:0px;
  background:white;
  border: 1px solid black;
  color:black;

  &:hover {
    background-color:white;
  }
}

.status-card__title {
  color:blue;
  text-decoration:underline;
  font-weight:bold;
}

.load-more {
  width:auto;
  margin:5px auto;
  background: $win95-bg;
  @include win95-outset();
  color:black;
  padding: 2px 5px;

  &:hover {
    background: $win95-bg;
    color:black;
  }
}

.status-card__description {
 color:black;
}

.account__display-name strong, .status__display-name strong {
  color:black;
  font-weight:bold;
}

.account .account__display-name {
  color:black;
}

.account {
  border-bottom: none;
}

.reply-indicator__content .status__content__spoiler-link, .status__content .status__content__spoiler-link {
  background:$win95-bg;
  @include win95-outset();
}

.reply-indicator__content .status__content__spoiler-link:hover, .status__content .status__content__spoiler-link:hover {
  background:$win95-bg;
}

.reply-indicator__content .status__content__spoiler-link:active, .status__content .status__content__spoiler-link:active {
  @include win95-inset();
}

.reply-indicator__content a, .status__content a {
  color:blue;
}

.notification {
  border: 2px groove $win95-bg;
  margin:4px;
}

.notification__message {
  color:black;
  font-size:13px;
}

.notification__display-name {
  font-weight:bold;
}


.drawer__header {
  background: $win95-bg;
  @include win95-border-outset();
  justify-content:left;
  margin-bottom:0px;
  padding-bottom:2px;
  border-bottom:2px groove $win95-bg;
}

.drawer__tab {
  color:black;
  @include win95-outset();
  padding:5px;
  margin:2px;
  flex: 0 0 auto;
}

.drawer__tab:first-child::before {
  content:"Start";
  color:black;
  font-weight:bold;
  font-size:15px;
  width:80%;
  display:block;
  position:absolute;
  right:0px;

}

.drawer__tab:first-child {
  position:relative;
  padding:5px 15px;
  width:40px;
  font-size:0px;
  color:$win95-bg;

  background-image: url("~images/start.png");
  background-repeat:no-repeat;
  background-position:8%;
  background-clip:padding-box;
  background-size:auto 50%;
}

.drawer__header a:hover {
  background-color:transparent;
}

.drawer__header a:first-child:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAIAAACpTQvdAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAF3pUWHRBdXRob3IAAAiZS84oys9LzAEAC5oC6A7BY/IAAACWSURBVCiRhVJJDsQgDEuqOfRZ7a1P5gbP4uaJaEjTADMWQhHYjlk4p0wLnNdptdF4KvBUDyGzVwc2xO+uKtH+1o0ytEEmqFpuxlvFCGCxKbNIT56QCi2MzaA/2Mz+mERSOeqzJG2RUxkjdTabgPtFoZ1bZxcKvgPcLZVufAyR9Ni8v5dWDzfFx0giC1RvZFv6l35QQ/Mvv39XXgGzQpoAAAAASUVORK5CYII=");
  background-repeat:no-repeat;
  background-position:8%;
  background-clip:padding-box;
  background-size:auto 50%;
  transition:unset;
}

.drawer__tab:first-child {

}

.search {
  background:$win95-bg;
  padding-top:2px;
  padding:2px;
  border:2px outset $win95-bg;
  border-top-width:0px;
  border-bottom: 2px groove $win95-bg;
  margin-bottom:0px;
}

.search input {
  background-color:white;
  color:black;
  @include win95-border-slight-inset();
}

.search__input:focus {
  background-color:white;
}

.search-popout {
  box-shadow: unset;
  color:black;
  border-radius:0px;
  background-color:$win95-tooltip-yellow;
  border:1px solid black;

  h4 {
    color:black;
    text-transform: none;
    font-weight:bold;
  }
}

.search-results__header {
  background-color: $win95-bg;
  color:black;
  border-bottom:2px groove $win95-bg;
}

.search-results__hashtag {
  color:blue;
}

.search-results__section h5:before {
    display: none;
}

.search-results__section h5 {
  background: #bfbfbf;
  span {
    color: black;
    padding: 0px 2px;
  }
}

.search-results__section {
    border: 3px groove white;
    margin: 11px 6px 9px 3px;
}

.search-results__section .account:hover,
.search-results__section .account:hover .account__display-name,
.search-results__section .account:hover .account__display-name strong,
.search-results__section .search-results__hashtag:hover {
  background-color:$win95-window-header;
  color:white;
}

.search__icon .fa {
  color:#808080;

  &.active {
    opacity:1.0;
  }

  &:hover {
    color: #808080;
  }
}

.trends__item__name a,
.trends__item__current {
  color: black;
}

.drawer__inner,
.drawer__inner.darker {
  background-color:$win95-bg;
  border: 2px outset $win95-bg;
  border-top-width:0px;
}

.navigation-bar {
  color:black;
}

.navigation-bar strong {
  color:black;
  font-weight:bold;
}

.compose-form .autosuggest-textarea__textarea,
.compose-form .spoiler-input__input {
  border-radius:0px;
  @include win95-border-slight-inset();
}

.compose-form .autosuggest-textarea__textarea {
  border-bottom:0px;
}

.compose-form__uploads-wrapper {
  border-radius:0px;
  border-bottom:1px inset $win95-bg;
  border-top-width:0px;
}

.compose-form__upload-wrapper {
  border-left:1px inset $win95-bg;
  border-right:1px inset $win95-bg;
}

.compose-form .compose-form__buttons-wrapper {
  background-color: $win95-bg;
  border:2px groove $win95-bg;
  margin-top:4px;
  padding:4px 8px;
}

.compose-form__buttons {
  background-color:$win95-bg;
  border-radius:0px;
  box-shadow:unset;
}

.compose-form__buttons-separator {
  border-left: 2px groove $win95-bg;
}

.compose-form__poll-wrapper .icon-button.disabled {
  color: $win95-mid-grey;
}

.privacy-dropdown.active .privacy-dropdown__value.active,
.advanced-options-dropdown.open .advanced-options-dropdown__value {
  background: $win95-bg;
}

.privacy-dropdown.active .privacy-dropdown__value.active .icon-button {
  color: $win95-dark-grey;
}

.privacy-dropdown.active
.privacy-dropdown__value {
  background: $win95-bg;
  box-shadow:unset;
}

.privacy-dropdown__option.active, .privacy-dropdown__option:hover,
.privacy-dropdown__option.active:hover {
  background:$win95-window-header;
}

.privacy-dropdown__dropdown,
.privacy-dropdown.active .privacy-dropdown__dropdown,
.advanced-options-dropdown__dropdown,
.advanced-options-dropdown.open .advanced-options-dropdown__dropdown
{
  box-shadow:unset;
  color:black;
  @include win95-outset();
  background: $win95-bg;
}

.privacy-dropdown__option__content {
  color:black;
}

.privacy-dropdown__option__content strong {
  font-weight:bold;
}

.compose-form {
  .compose-form__warning::before {
    content:"Tip:";
    font-weight:bold;
    display:block;
    position:absolute;
    top:-10px;
    background-color:$win95-bg;
    font-size:11px;
    padding: 0px 5px;
  }

  .compose-form__warning {
    position:relative;
    box-shadow:unset;
    border:2px groove $win95-bg;
    background-color:$win95-bg;
    color:black;
  }

  .compose-form__warning a {
    color:blue;
  }

  .compose-form__warning strong {
    color:black;
    text-decoration:underline;
  }
}

.compose-form__buttons button.active:last-child {
  @include win95-border-inset();
  background: #dfdfdf;
  color:#7f7f7f;
}

.compose-form__upload-thumbnail {
  border-radius:0px;
  border:2px groove $win95-bg;
  background-color:$win95-bg;
  padding:2px;
  box-sizing:border-box;
}

.compose-form__upload-thumbnail .icon-button {
  max-width:20px;
  max-height:20px;
  line-height:10px !important;
}

.compose-form__upload-thumbnail .icon-button::before {
  content:"X";
  font-size:13px;
  font-weight:bold;
  color:black;
}

.compose-form__upload-thumbnail .icon-button i {
  display:none;
}

.emoji-picker-dropdown__menu {
  z-index:2;
}

.emoji-dialog.with-search {
  box-shadow:unset;
  border-radius:0px;
  background-color:$win95-bg;
  border:1px solid black;
  box-sizing:content-box;

}

.emoji-dialog .emoji-search {
  color:black;
  background-color:white;
  border-radius:0px;
  @include win95-inset();
}

.emoji-dialog .emoji-search-wrapper {
  border-bottom:2px groove $win95-bg;
}

.emoji-dialog .emoji-category-title {
  color:black;
  font-weight:bold;
}

.reply-indicator {
  background-color:$win95-bg;
  border-radius:3px;
  border:2px groove $win95-bg;
}

.button {
  background-color:$win95-bg;
  @include win95-outset();
  border-radius:0px;
  color:black;
  font-weight:bold;

  &:hover, &:focus, &:disabled {
    background-color:$win95-bg;
  }

  &:active {
    @include win95-inset();
  }

  &:disabled {
    color: #808080;
    text-shadow: 1px 1px 0px #efefef;

    &:active {
      @include win95-outset();
    }
  }

}

.button.button-secondary {
  background-color: $win95-bg;
}

.column-link {
  background-color:transparent;
  color:black;
  &:hover {
    background-color: $win95-window-header;
    color:white;
  }
}

.column-link__badge {
  background-image: url('~images/alert_badge.png');
  background-repeat: no-repeat;
  background-size:contain;
  background-color:transparent;
  border-radius:0;
  box-sizing: border-box;
  width: 24px;
  height:24px;
  padding-top:4px;
  padding-left:0px;
  padding-right:1px;
  text-align:center;
  position:relative;
  top:2px;
}

.column-link:hover .column-link__badge {
  color:black;
}

.column-subheading {
  background-color:$win95-bg;
  color:black;
  @include win95-border-outset-sides-only;
}

.column {
  overflow-y:auto;
}

.getting-started {
  background: none;
  position:relative;
  top:-30px;
  padding-top:30px;
  z-index:10;
  overflow-y:auto;
  background-color: $win95-cyan;
}

.getting-started__wrapper {
  padding-top:0px;

  box-shadow: inset -1px 0px 0px #000000,
              inset 1px 1px 0px #ffffff,
              inset -2px 0px 0px #808080,
              inset 2px 2px 0px #dfdfdf;
  border-radius:0px;

  background-color:$win95-bg;
  border-bottom: 2px groove $win95-bg;

  height: unset !important;

  .navigation-bar {
    padding-left: 45px;
  }

  .column-subheading {
    font-size:0px;
    margin:0px;
    padding:0px;
    background-color: transparent;
    color:black;
    border-bottom: 2px groove $win95-bg;
    text-transform: none;
  }

}

.column-link {
    background-size:32px 32px;
    background-repeat:no-repeat;
    background-position: 36px 50%;
    padding-left:45px;

    &:hover {
      background-size:32px 32px;
      background-repeat:no-repeat;
      background-position: 36px 50%;
    }

    i {
      font-size: 0px;
      width:32px;
    }
  }

.getting-started__wrapper::before {
  content: "Start";
  display:block;
  color:black;
  font-weight:bold;
  font-size:15px;
  position:absolute;
  top:0px;
  left:0px;
  padding:5px 15px;
  width:50px;
  font-size:16px;
  padding-left:25px;
  background-color:$win95-bg;
  z-index:12;

  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAIAAACpTQvdAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAF3pUWHRBdXRob3IAAAiZS84oys9LzAEAC5oC6A7BY/IAAACWSURBVCiRhVJJDsQgDEuqOfRZ7a1P5gbP4uaJaEjTADMWQhHYjlk4p0wLnNdptdF4KvBUDyGzVwc2xO+uKtH+1o0ytEEmqFpuxlvFCGCxKbNIT56QCi2MzaA/2Mz+mERSOeqzJG2RUxkjdTabgPtFoZ1bZxcKvgPcLZVufAyR9Ni8v5dWDzfFx0giC1RvZFv6l35QQ/Mvv39XXgGzQpoAAAAASUVORK5CYII=");
  background-repeat:no-repeat;
  background-position:8%;
  background-clip:padding-box;
  background-size:auto 50%;

  @include win95-border-inset();
}


@media screen and (min-width: 360px) {
  .getting-started__wrapper{
    margin-bottom:0px;
  }
}

@media screen and (max-width: 360px) {
  .getting-started {
    top:0px;
    padding-top:0px;
  }

  .getting-started__wrapper::before {
    display:none;
  }
}

.getting-started__footer {
  background-color: $win95-bg;
  padding:0px;
  padding-bottom:10px;
  position:relative;
  top:0px;

  @include win95-outset-no-highlight();

  p {
    margin-left: 45px;
  }

  ul {
    display:block;
    li {
      cursor:pointer;
      display:block;
      font-size:0px;
      padding:0px;
      line-height:0;
      a {
        padding:15px;
        padding-left:77px;
        line-height:1em;
        font-size:16px;
        display:block;
        color:black;
        background-size:32px 32px;
        background-repeat:no-repeat;
        background-position: 36px 50%;
        &:hover {
          text-decoration:none;
        }
      }

      &:hover {
        background-color: $win95-window-header;
        a {
          color:white;
        }
      }
    }
  }
}

.getting-started__footer::after {
  content:"Mastodon 95";
  font-weight:bold;
  font-size:23px;
  color:white;
  line-height:30px;
  padding-left:20px;
  padding-right:40px;

  left:0px;
  box-sizing:border-box;
  bottom:-32px;
  display:block;
  position:absolute;
  background-color:#7f7f7f;
  width:1000px;
  height:32px;

  z-index:11;

  border-left: 2px solid #404040;
  border-top: 2px solid #efefef;
  border-right: 2px solid #efefef;
  border-radius:0px;

  -ms-transform: rotate(-90deg);

  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transform-origin:top left;
}

.layout-single-column .getting-started__footer::after {
  display: none;
}

.getting-started__wrapper + .flex-spacer {
  display:none;
}

.column-link[href="/web/timelines/home"] {
  background-image: url("~images/icon_home.png");
  &:hover { background-image: url("~images/icon_home.png"); }
}
.column-link[href="/web/notifications"] {
  background-image: url("~images/icon_notifications.png");
  &:hover { background-image: url("~images/icon_notifications.png"); }
}
.column-link[href="/web/timelines/public"] {
  background-image: url("~images/icon_public.png");
  &:hover { background-image: url("~images/icon_public.png"); }
}
.column-link[href="/web/timelines/public/local"] {
  background-image: url("~images/icon_local.png");
  &:hover { background-image: url("~images/icon_local.png"); }
}
.column-link[href="/web/timelines/direct"] {
  background-image: url("~images/icon_direct.png");
  &:hover { background-image: url("~images/icon_direct.png"); }
}
.column-link[href="/web/pinned"] {
  background-image: url("~images/icon_pin.png");
  &:hover { background-image: url("~images/icon_pin.png"); }
}
.column-link[href="/web/favourites"] {
  background-image: url("~images/icon_likes.png");
  &:hover { background-image: url("~images/icon_likes.png"); }
}
.column-link[href="/web/lists"] {
  background-image: url("~images/icon_lists.png");
  &:hover { background-image: url("~images/icon_lists.png"); }
}
.column-link[href="/web/follow_requests"] {
  background-image: url("~images/icon_follow_requests.png");
  &:hover { background-image: url("~images/icon_follow_requests.png"); }
}
.column-link[href="/web/blocks"] {
  background-image: url("~images/icon_blocks.png");
  &:hover { background-image: url("~images/icon_blocks.png"); }
}
.column-link[href="/web/domain_blocks"] {
  background-image: url("~images/icon_domain_blocks.png");
  &:hover { background-image: url("~images/icon_domain_blocks.png"); }
}
.column-link[href="/web/mutes"] {
  background-image: url("~images/icon_mutes.png");
  &:hover { background-image: url("~images/icon_mutes.png"); }
}
.column-link[href="/web/directory"] {
  background-image: url("~images/icon_profile_directory.png");
  &:hover { background-image: url("~images/icon_profile_directory.png"); }
}
.column-link[href="/web/bookmarks"] {
  background-image: url("~images/icon_bookmarks.png");
  &:hover { background-image: url("~images/icon_bookmarks.png"); }
}

.getting-started__footer ul li a[href="/web/keyboard-shortcuts"] {
  background-image: url("~images/icon_keyboard_shortcuts.png");
  &:hover { background-image: url("~images/icon_keyboard_shortcuts.png"); }
}
.getting-started__footer ul li a[href="/invites"] {
  background-image: url("~images/icon_invite.png");
  &:hover { background-image: url("~images/icon_invite.png"); }
}
.getting-started__footer ul li a[href="/terms"] {
  background-image: url("~images/icon_tos.png");
  &:hover { background-image: url("~images/icon_tos.png"); }
}
.getting-started__footer ul li a[href="https://docs.joinmastodon.org"] {
  background-image: url("~images/icon_docs.png");
  &:hover { background-image: url("~images/icon_docs.png"); }
}
.getting-started__footer ul li a[href="/about/more"] {
  background-image: url("~images/icon_about.png");
  &:hover { background-image: url("~images/icon_about.png"); }
}
.getting-started__footer ul li a[href="/auth/sign_out"] {
  background-image: url("~images/icon_logout.png");
  &:hover { background-image: url("~images/icon_logout.png"); }
}
.getting-started__footer ul li a[href="https://joinmastodon.org/apps"] {
  background-image: url("~images/icon_mobile_apps.png");
  &:hover { background-image: url("~images/icon_mobile_apps.png"); }
}
.getting-started__footer ul li a[href="/settings/applications"] {
  background-image: url("~images/icon_developers.png");
  &:hover { background-image: url("~images/icon_developers.png"); }
}
.getting-started__footer ul li a[href="/auth/edit"] {
  background-image: url("~images/icon_settings.png");
  &:hover { background-image: url("~images/icon_settings.png"); }
}

.column .static-content.getting-started {
  display:none;
}

.keyboard-shortcuts kbd {
  background-color: $win95-bg;
}

.account__header {
  background-color:#7f7f7f;
}

.account__header .account__header__content {
  color:white;
}

.account__header__fields {
  border-left: 1px solid black;
  border-top: 1px solid black;

  dt {
    background-color:$win95-bg;
    color:black;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid $win95-mid-grey;
    border-right: 1px solid $win95-mid-grey;
  }
  dd {
    background-color:white;
    border: 1px solid $win95-bg;
    color:black;
  }
  dd,dt {
    padding: 5px 8px;
  }
}

.account-authorize__wrapper {
  border: 2px groove $win95-bg;
  margin: 2px;
  padding:2px;
}

.domain .domain__domain-name strong {
  color: black;
}

.account--panel {
  background-color: $win95-bg;
  border:0px;
  border-top: 2px groove $win95-bg;
}

.account-authorize .account__header__content {
  color:black;
  margin:10px;
}

.account__action-bar__tab > span {
  color:black;
  font-weight:bold;
}

.account__action-bar__tab strong {
  color:black;
}

.account__action-bar {
  border: unset;
}

.account__action-bar__tab {
  border: 1px outset $win95-bg;
}

.account__action-bar__tab:active {
  @include win95-inset();
}

.account__section-headline {
  background: $win95-bg;
  margin-top: 5px;

  & > a {
    @include win95-tab();
    color: black;
    padding: 5px;

    &.active {
      background: $win95-bg;
      @include win95-inset();
      color: black;

      &:before, &:after {
        display: none;
      }
    }
  }
}

.dropdown--active .dropdown__content > ul,
.dropdown-menu {
  background:$win95-tooltip-yellow;
  border-radius:0px;
  border:1px solid black;
  box-shadow:unset;
  margin-top: 6px;
}

.dropdown-menu a {
  background-color:transparent;
}

.dropdown-menu__arrow {
  &.bottom {
    border-bottom-color: $win95-tooltip-yellow;
  }

  &.top {
    border-top-color: $win95-tooltip-yellow;
  }

  &:before {
    position: relative;
    border: 0 solid transparent;
    display: block;
    content: '';
    left: -8px;
    z-index: -1;
  }

  &.bottom::before {
    border-bottom-color: black;
    border-width: 0 8px 6px;
    bottom: 1px;
  }

  &.top::before {
    border-top-color: black;
    border-width: 6px 8px 0;
    top: -5px;
  }
}

.dropdown-menu {
  margin-top: 6px;
}

.dropdown--active::after {
  display:none;
}

.dropdown--active .icon-button {
  color:black;
  @include win95-inset();
}

.dropdown--active .dropdown__content > ul > li > a {
  background:transparent;
}

.dropdown--active .dropdown__content > ul > li > a:hover {
  background:transparent;
  color:black;
  text-decoration:underline;
}

.dropdown__sep,
.dropdown-menu__separator
{
  border-color:#7f7f7f;
}

.detailed-status__action-bar-dropdown .dropdown--active .dropdown__content.dropdown__left {
  left: unset;
}

.dropdown > .icon-button, .detailed-status__action-bar .icon-button,
.status__action-bar .icon-button, .star-icon i {
    /* i don't know what's going on with the inline
       styles someone should look at the react code */
    height: 25px !important;
    width: 28px !important;
    box-sizing: border-box;
}

.icon-button {
  height: auto!important;
  width: auto!important;
}

.status__action-bar-dropdown .icon-button {
  position: relative;
  top: -1px;
}

.fa-user-plus, .fa-user-times {
  padding: 2px 0px 2px 1px;
}

.fa-ellipsis-h {
  padding-top: 3px;
}

.status__action-bar-button .fa-floppy-o {
  padding-top: 2px;
}

.notification .status__action-bar {
  border-bottom: none;
}

.notification .status {
  margin-bottom: 4px;
}

.status__wrapper .status {
  margin-bottom: 3px;
}

.status__wrapper {
  margin-bottom: 8px;
}

.status__prepend {
  color: black;
  font-size: 13px;
}

.icon-button .fa-retweet {
  position: relative;
  top: -1px;
}

.embed-modal, .error-modal, .onboarding-modal,
.actions-modal, .boost-modal, .confirmation-modal, .report-modal {
  @include win95-outset();
  background:$win95-bg;
}

.actions-modal::before,
.boost-modal::before,
.confirmation-modal::before,
.report-modal::before {
  content: "Confirmation";
  display:block;
  background:$win95-window-header;
  color:white;
  font-weight:bold;
  padding-left:2px;
}

.boost-modal::before {
  content: "Boost confirmation";
}

.boost-modal__action-bar > div > span:before {
  content: "Tip: ";
  font-weight:bold;
}

.boost-modal__action-bar, .confirmation-modal__action-bar, .report-modal__action-bar {
  background:$win95-bg;
  margin-top:-15px;
}

.embed-modal h4, .error-modal h4, .onboarding-modal h4 {
  background:$win95-window-header;
  color:white;
  font-weight:bold;
  padding:2px;
  font-size:13px;
  text-align:left;
}

.media-modal .media-modal__close {
    font-size: 14px!important;
    line-height: 17px!important;
    margin-right: 4vw;
    margin-top: 4vh;
}

.confirmation-modal__action-bar {
  .confirmation-modal__cancel-button {
    color:black;

    &:active,
    &:focus,
    &:hover {
      color:black;
    }

    &:active {
      @include win95-inset();
    }
  }
}

.embed-modal .embed-modal__container .embed-modal__html,
.embed-modal .embed-modal__container .embed-modal__html:focus {
  background:white;
  color:black;
  @include win95-inset();
}

.report-modal__target .media-modal__close {
  top: 3px;
  right: 0px;
  font-size: 12px!important;
  line-height: 13px!important;
}

.report-modal__comment p {
    font-size: 12px;
    margin-bottom: 1em;
    padding-left: 3px;
}

.report-modal__comment .setting-text.light {
    border-radius: 0;
}

.report-modal__container {
    margin-right: 2px;
}

.report-modal::before {
    height: 22px;
    line-height: 23px;
}

.status-check-box__status .media-gallery {
    margin: unset;
}

.modal-root__overlay,
.account__header > div {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAFnpUWHRUaXRsZQAACJnLzU9JzElKBwALgwLXaCRlPwAAABd6VFh0QXV0aG9yAAAImUvOKMrPS8wBAAuaAugOwWPyAAAAEUlEQVQImWNgYGD4z4AE/gMADwMB/414xEUAAAAASUVORK5CYII=');
}


.admin-wrapper::before {
  position:absolute;
  top:0px;
  content:"Control Panel";
  color:white;
  background-color:$win95-window-header;
  font-size:13px;
  font-weight:bold;
  width:calc(100%);
  margin: 2px;
  display:block;
  padding:2px;
  padding-left:22px;
  box-sizing:border-box;
}

.admin-wrapper {
  position:relative;
  background: $win95-bg;
  @include win95-outset();
  width:70vw;
  height:80vh;
  min-height:80vh;
  margin:10vh auto;
  color: black;
  padding-top:24px;
  flex-direction:column;
  overflow:hidden;
}

@media screen and (max-width: 1120px) {
  .admin-wrapper {
    width:90vw;
    height:95vh;
    margin:2.5vh auto;
  }
}

@media screen and (max-width: 740px) {
  .admin-wrapper {
    width:100vw;
    height:95vh;
    height:calc(100vh - 24px);
    margin:0px 0px 0px 0px;
  }
}

.admin-wrapper .sidebar-wrapper {
  position:static;
  height:auto;
  min-height:auto;
  flex: 0 0 auto;
  margin:2px;
}

.admin-wrapper .content-wrapper {
  flex: 1 1 auto;
  width:calc(100% - 20px);
  max-width:calc(100% - 20px);
  @include win95-border-outset();
  position:relative;
  margin-left:10px;
  margin-right:10px;
  margin-bottom:40px;
  box-sizing:border-box;
  overflow-y:scroll;
  height: 100%;
}

.admin-wrapper .content {
  background-color: $win95-bg;
  width: 100%;
  max-width:100%;
  min-height:100%;
  box-sizing:border-box;
  position:relative;
}
.admin-wrapper .content h4 {
  color: black;
}

.admin-wrapper .sidebar {
  position:static;
  background: $win95-bg;
  color:black;
  width: 100%;
  height:auto;
  padding-bottom: 20px;
}

.admin-wrapper .sidebar .logo {
  position:absolute;
  top:2px;
  left:4px;
  width:18px;
  height:18px;
  margin:0px;
}

.admin-wrapper .sidebar > ul {
  background: $win95-bg;
  margin:0px;
  margin-left:8px;
  color:black;

  & > li {
    display:inline-block;

    &#settings,
    &#admin {
      padding:2px;
      border: 0px solid transparent;
    }

    &#logout {
      position:absolute;
      @include win95-outset();
      right:12px;
      bottom:10px;
    }

    &#web {
      display:inline-block;
      @include win95-outset();
      position:absolute;
      left: 12px;
      bottom: 10px;
    }

    & > a {
      display:inline-block;
      @include win95-tab();
      padding:2px 5px;
      margin:0px;
      color:black;
      vertical-align:baseline;

      &.selected {
        background: $win95-bg;
        color:black;
        padding-top: 4px;
        padding-bottom:4px;
      }

      &:hover {
        background: $win95-bg;
        color:black;
      }
    }

    & > ul {
      width:calc(100% - 20px);
      background: transparent;
      position:absolute;
      left: 10px;
      top:54px;
      z-index:3;

      & > li {
        background: $win95-bg;
        display: inline-block;
        vertical-align:baseline;

        & > a {
          background: $win95-bg;
          @include win95-tab();
          color:black;
          padding:2px 5px;
          position:relative;
          z-index:3;

          &.selected {
            background: $win95-bg;
            color:black;
            padding-bottom:4px;
            padding-top: 4px;
            padding-right:7px;
            margin-left:-2px;
            margin-right:-2px;
            position:relative;
            z-index:4;

            &:first-child {
              margin-left:0px;
            }

            &:hover {
              background: transparent;
              color:black;
            }
          }

          &:hover {
            background: $win95-bg;
            color:black;
          }
        }
      }
    }
  }
}

.admin-wrapper .sidebar ul .simple-navigation-active-leaf a:hover {
  background: $win95-bg;
}

@media screen and (max-width: 1520px) {
  .admin-wrapper .sidebar > ul > li > ul {
    max-width:1000px;
  }

  .admin-wrapper .sidebar {
    padding-bottom: 45px;
  }
}

@media screen and (max-width: 600px) {
  .admin-wrapper .sidebar > ul > li > ul {
    max-width:500px;
  }

  .admin-wrapper {
    .sidebar {
      padding:0px;
      padding-bottom: 70px;
      width: 100%;
      height: auto;
    }
    .content-wrapper {
      overflow:auto;
      height:80%;
      height:calc(100% - 150px);
    }
  }
}

.flash-message {
  background-color:$win95-tooltip-yellow;
  color:black;
  border:1px solid black;
  border-radius:0px;
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
}

.admin-wrapper table {
  background-color: white;
  @include win95-border-slight-inset();
}

.admin-wrapper .table th, .table td {
  background-color:transparent;
}

.admin-wrapper button.table-action-link,
.admin-wrapper a.table-action-link,
.admin-wrapper button.table-action-link:hover,
.admin-wrapper a.table-action-link:hover,
.admin-wrapper a.name-tag,
.admin-wrapper .name-tag,
.admin-wrapper a.inline-name-tag,
.admin-wrapper .inline-name-tag,
.admin-wrapper .content h2,
.admin-wrapper .content h3,
.simple_form .input.with_label .label_input > label,
.admin-wrapper .content h6,
.admin-wrapper .content > p,
.admin-wrapper .content .muted-hint,
.simple_form span.hint,
.simple_form h4,
.simple_form .check_boxes .checkbox label,
.simple_form .input.with_label.boolean .label_input > label,
.filters .filter-subset a,
.simple_form .input.radio_buttons .radio label,
a.table-action-link,
a.table-action-link:hover,
.simple_form .input.with_block_label > label,
.simple_form p.hint,
.admin-wrapper .content > p strong,
.simple_form .input.with_floating_label .label_input > label,
.admin-wrapper .content .fields-group h6 {
  color:black;
}

.report-card {
  background: white;
  border: 1px solid black;
  border-radius: 0px;
}

.report-card__summary__item:hover {
  background: white;
}

.report-card__summary__item__content a {
  color: black;
}

.directory__tag > a, .directory__tag > div,
.directory__tag > a:hover, .directory__tag > a:active, .directory__tag > a:focus {
  background: white;
  border: 1px solid black;
  border-radius: 0px;
}

.admin-wrapper .content .directory__tag h4 {
  color: black;
}

.simple_form .hint code {
  background: $win95-bg;
  border-radius: 0px;
}

.input-copy {
  background: transparent;
  border: 0px solid transparent;
}

.table > tbody > tr:nth-child(2n+1) > td,
.table > tbody > tr:nth-child(2n+1) > th {
  background-color:white;
}

.simple_form input[type=text],
.simple_form input[type=number],
.simple_form input[type=email],
.simple_form input[type=password],
.simple_form textarea {
  color:black;
  background-color:white;
  @include win95-border-slight-inset();

  &:active, &:focus {
    background-color:white;
  }
}

.simple_form button,
.simple_form .button,
.simple_form .block-button
{
  background: $win95-bg;
  @include win95-outset();
  color:black;
  font-weight: normal;

  &:hover {
    background: $win95-bg;
  }
}

.simple_form .warning, .table-form .warning
{
  background: $win95-tooltip-yellow;
  color:black;
  box-shadow: unset;
  text-shadow:unset;
  border:1px solid black;

  a {
    color: blue;
    text-decoration:underline;
  }
}

.simple_form button.negative,
.simple_form .button.negative,
.simple_form .block-button.negative
{
  background: $win95-bg;
}

.simple_form select {
  background: white;
  border-radius: 0px;
  color: black;
}

.filters .filter-subset  {
  border: 2px groove $win95-bg;
  padding:2px;
}

.filters .filter-subset a::before {
  content: "";
  background-color:white;
  border-radius:50%;
  border:2px solid black;
  border-top-color:#7f7f7f;
  border-left-color:#7f7f7f;
  border-bottom-color:#f5f5f5;
  border-right-color:#f5f5f5;
  width:12px;
  height:12px;
  display:inline-block;
  vertical-align:middle;
  margin-right:2px;
}

.filters .filter-subset a.selected::before {
  background-color:black;
  box-shadow: inset 0 0 0 3px white;
}

.filters .filter-subset a,
.filters .filter-subset a:hover,
.filters .filter-subset a.selected {
  color:black;
  border-bottom: 0px solid transparent;
}

.drawer__inner__mastodon {
  display: none;
}

.list-editor h4 {
  padding: 2px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  background: #00007f;
  border-radius: 0;
}

.list-editor {
  @include win95-border-outset();
  box-shadow: unset;
}

.list-editor .drawer__inner {
  @include win95-inset();
  border-radius: 0;
}

.batch-table__toolbar {
  border-radius: 0px;
  background-color:white;
  border: 1px solid black;
}

.batch-table__row {
  border: 1px solid black;
  background-color: white;

  &:hover {
    background-color: white;
  }
}

.batch-table__row:nth-child(2n) {
  background-color: white;
}

.dashboard__counters > div > div,
.dashboard__counters > div > a {
    background-color: $win95-bg;
    border: 1px solid black;
    border-radius: 1px;
    color:black;

    &:hover {
      background-color: $win95-bg;
    }
}

.dashboard__counters__label,
.dashboard__widgets a:not(.name-tag),
.dashboard__counters__num {
    color:black;
}

.card {
  & > a, & > a:hover {
    box-shadow: none;

    .card__img {
      border-radius: 0px;
      border: 1px solid black;
    }

    .card__bar {
      @include win95-outset();
      background-color: $win95-bg;

      .display-name {
          strong, span {
          color:black;
        }
      }
    }
  }
}

/* Public layout stuff */
body {
  background: $win95-cyan;
}

.public-layout {
  max-width: 960px;
  margin:10px auto;
  background: $win95-bg;
  padding:0px;
  @include win95-outset();

  .header {
    background: $win95-window-header;
    @include win95-border-outset();
    height: 22px;
    margin: 0px;
    padding:0px;
    border-radius: 0px;

    .brand {
      padding: 2px;
    }

    .nav-button {
      @include win95-outset();
      background: $win95-bg;
      color:black;
      margin: 2px;
      margin-bottom: 0px;
      &:hover {
        background: $win95-bg;
        color:black;
      }
    }
  }
  .footer {
    background: none;
    &, h4, ul a, .grid .column-2 h4 a {
      color: black;
    }
  }

  .button.logo-button {
    @include win95-outset();
    background: $win95-bg;
    color:black;
    &:hover {
      background: $win95-bg;
      color:black;
    }
    svg {
       visibility:hidden;
    }
    &, &:hover {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAIAAACpTQvdAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAF3pUWHRBdXRob3IAAAiZS84oys9LzAEAC5oC6A7BY/IAAACWSURBVCiRhVJJDsQgDEuqOfRZ7a1P5gbP4uaJaEjTADMWQhHYjlk4p0wLnNdptdF4KvBUDyGzVwc2xO+uKtH+1o0ytEEmqFpuxlvFCGCxKbNIT56QCi2MzaA/2Mz+mERSOeqzJG2RUxkjdTabgPtFoZ1bZxcKvgPcLZVufAyR9Ni8v5dWDzfFx0giC1RvZFv6l35QQ/Mvv39XXgGzQpoAAAAASUVORK5CYII=");
      background-repeat:no-repeat;
      background-position:8%;
      background-clip:padding-box;
      background-size:auto 50%;
    }
  }

  .public-account-header {
    @include win95-reset();
    padding: 4px;
    .public-account-header__image {
      @include win95-border-slight-inset();
      border-radius: 0px;
    }
  }

  .public-account-header__bar {
    &, &:before {
      background: transparent;
    }
    .avatar img {
      @include win95-border-slight-inset();
      filter: saturate(1.8) brightness(1.1);
      border-radius: 0px;
      background: darken($win95-bg, 9.09%);
    }
  }
  .public-account-header__extra__links {
    margin-top: 0px;
    a, a strong {
      color: black;
    }
  }

  .public-account-header__tabs {
    position: relative;
  }

  .public-account-header__tabs__name {
    display: inline-block;
    position: relative;
    background: $win95-tooltip-yellow;
    border: 1px solid black;
    padding: 4px;

    h1, h1 small {
      color:black;
      text-shadow: unset;
      text-overflow: unset;
    }

    margin-bottom: 24px;

    &:after {
      content: "";
      display:block;
      position:absolute;
      left: 0px;
      bottom: -20px;
      width: 0px;
      height: 0px;
      border-left: 20px solid $win95-tooltip-yellow;
      border-bottom: 20px solid transparent;
    }
    &:before {
      content: "";
      display:block;
      position:absolute;
      left: -1px;
      bottom: -22px;
      width: 0px;
      height: 0px;
      border-left: 22px solid black;
      border-bottom: 22px solid transparent;
    }
  }

  .public-account-header__tabs__tabs {
    .details-counters {
      @include win95-border-groove();
      .counter {
        .counter-number, .counter-label {
          color: black;
        }
        &:after {
          border-bottom-width: 0px;
        }
        &.active {
         @include win95-border-slight-inset();
        }
      }
    }
  }

  .public-account-bio {
    @include win95-reset();
    @include win95-border-groove();
    background: $win95-bg;
    margin-right: 10px;
    .account__header__content, .roles {
      color: black;
    }
  }
  .public-account-bio__extra {
    color: black;
  }

  .status__prepend {
    padding-top:5px;
  }
  .status__content ,
  .reply-indicator__content {
    .status__content__spoiler-link {
      color: $win95-dark-grey;
    }
  }
  .account__section-headline {
    margin-left: 10px;
  }
  .card-grid {
    margin-left: 10px;
  }
  .status {
    padding: 15px 15px 55px 78px;
  }
}

@media screen and (max-width: 1255px) {
  .container {
    width: 100%;
    padding: 0px;
  }
}

.hero-widget {
  box-shadow: none;
  @include win95-border-groove();
  background: $win95-bg;
  padding: 8px;
  margin-right: 10px;
  margin-top: 10px;
}
.hero-widget__text {
  background: none;
  color: black;
}
.hero-widget__img {
  background: none;
  border-radius: 0px;
  border: 1px solid black;
  img {
    border-radius: 0px;
  }
}

.activity-stream {
  @include win95-reset();
  @include win95-border-groove();

  background: $win95-bg;
  margin-top: 10px;
  margin-left: 10px;
  .entry {
    background: none;
    &:first-child:last-child, &:first-child {
      .detailed-status, .status, .load-more {
        border-radius: 0px;
      }
    }
  }
}

.nothing-here {
  @include win95-reset();
  background: transparent;
  color: black;
}

.flash-message.notice {
  border: 1px solid black;
  background: $win95-tooltip-yellow;
  color:black;
}

.layout-single-column .compose-panel {
  background: $win95-bg;
}

.layout-single-column .status__wrapper .status {
  padding-bottom: 50px;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAAAAABX3VL4AAAADklEQVQIW2P4f4DhwH8ACoADf0PlskQAAAAASUVORK5CYII=');

  &:hover {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAAAAABX3VL4AAAADklEQVQIW2P4f4DhwH8ACoADf0PlskQAAAAASUVORK5CYII=');
  }
}

::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-color: #efefef #404040 #404040 #efefef;
  border-style: solid;
  border-width: 2px;

  &:hover {
    background: #bfbfbf;
    border-color: #efefef #404040 #404040 #efefef;
    border-style: solid;
    border-width: 2px;
  }

  &:active {
    background: #bfbfbf;
    border-color: #404040 #efefef #efefef #404040;
  }
}

